import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    plugins: [createPersistedState()],
    state: {
        isProduction: process.env.VUE_APP_BASE_URL == 'https://76bm8iyk24.execute-api.us-east-1.amazonaws.com/v1',
        user: {
            firstName: '',
            access_token: '',
            publicImageLink: null,
            needsAdditionalInfo: null,
            email: '',
            producerId: null,
            role: null,
            isEmployee: null,
            employeePrivilleges: null,
            isRecipientRefused: null,
        },
        isDarkMode: false,
    },
    mutations: {
        setUserData(state, userData) {
            state.user.firstName = userData.firstName;
            state.user.access_token = userData.access_token;
            state.user.publicImageLink = userData.publicImageLink;
            state.user.needsAdditionalInfo = userData.needsAdditionalInfo;
            state.user.producerId = userData.producerId;
            state.user.role = userData.role;
            state.user.isEmployee = userData.isEmployee;
            state.user.employeePrivilleges = userData.employeePrivilleges;
        },

        setAdditionalInfo(state) {
            state.user.needsAdditionalInfo = false;
        },

        removeUserData(state) {
            state.user.access_token = null;
        },

        setApiKey(state, apiKey) {
            state.apiKey = apiKey; // Mutation para definir a API key
        },

        toggleDarkMode(state, newState) {
            state.isDarkMode = !newState;
        },
    },
})