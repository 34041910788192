<script>
export default {
  name: "Loader page",
};
</script>

<template>
  <div class="loaderPage">
    <span class="preloader"></span>
  </div>
</template>

<style scoped>
.loaderPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 19;
  right: 0px;
  transition: var(--slow-med);
}

.preloader {
  background-image: url("../../assets/logosPixGG/logo-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 25px;
  width: 80px;
  display: block;
  animation: pulse 2.2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: rotate(45deg) scale(1.1);
    opacity: 0.5;
    filter: blur(0.8px);
  }
  50% {
    transform: rotate(0deg) scale(1.4);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: rotate(225deg) scale(1.1);
    opacity: 0.5;
    filter: blur(0.8px);
  }
}
</style>