<script>
import LoaderPage from './components/Loaders/LoaderPage.vue';

export default {
    components: { LoaderPage },
    data() {
        return {
            app_name: "pixgg",
            loading: false,
        };
    },
    beforeCreate() {
        this.$router.beforeEach((to, from, next) => {
            this.loading = true;
            next();
        });

        this.$router.afterEach(() => {
          setTimeout(() => {
                this.loading = false;
            }, 1000);
        });
    }
};
</script>
<template>
    <div>
        <div v-if="!loading">
            <router-view></router-view>
        </div>

        <transition>
            <LoaderPage v-if="loading" />
        </transition>
    </div>
</template>





