import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Presentation',
    component: () => import('../pages/Presentation.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'logged-default-page',
    component: () => import('../pages/loggedPages/LoggedDefaultPage.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../pages/loggedPages/Dashboard.vue'),
      },
      {
        path: '/integracao/:parametro?',
        name: 'Integration',
        component: () => import('../pages/loggedPages/Integration.vue'),
      },
      {
        path: '/editar-perfil',
        name: 'edit-profile',
        component: () => import('../pages/loggedPages/EditProfile.vue'),
      },
      {
        path: '/saldo-e-saque',
        name: 'balance-and-withdrawal',
        component: () => import('../pages/loggedPages/BalanceAndWithdrawal.vue'),
      },
      {
        path: '/painel-de-donates',
        name: 'donates-page',
        component: () => import('../pages/loggedPages/DonatesPage.vue'),
      },
      {
        path: '/metas',
        name: 'metas',
        component: () => import('../pages/loggedPages/GoalsPage.vue'),
      },
      {
        path: '/checkout-builder',
        name: 'checkout-builder',
        component: () => import('../pages/loggedPages/CheckoutBuilder.vue'),
      },
    ]
  },
  {
    redirect: { name: 'Presentation' },
    name: 'logged-out-pages',
    component: () => import('../pages/LoggedOutPages.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login.vue')
      },
      {
        path: '/criar-conta',
        name: 'Create-account',
        component: () => import('../pages/CreateAccount/CreateAccount.vue'),
      },
      {
        path: '/redefinir-senha/:code',
        name: 'recovery-password',
        component: () => import('../pages/RecoveyPassword.vue'),
      },
      {
        path: '/criar-conta/ultimo-passo',
        name: 'Additional-user-information',
        component: () => import('../pages/CreateAccount/AdditionalUserInfo.vue')
      },
      {
        path: '/404',
        name: '404',
        component: () => import('../pages/404.vue')
      },
      {
        path: '/accept-offer/:guid',
        name: 'accept-offer',
        component: () => import('../pages/AcceptOffer.vue')
      },
      {
        path: '/:username',
        name: 'Checkout',
        component: () => import('../pages/Checkout.vue')
      },
    ]
  },
  {
    path: '/termos-de-uso',
    name: 'termos-de-uso',
    component: () => import('../pages/TermsOfUse.vue')
  },
  {
    path: '/politica-de-privacidade',
    name: 'politica-de-privacidade',
    component: () => import('../pages/PrivacyPolicy.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (to.name == 'Login'
    || to.name == 'Create-account'
    || to.name == 'recovery-password'
    || to.name == 'Additional-user-information'
    || to.name == '404'
    || to.name == 'Checkout'
  ) {
    document.body.classList.remove('dark-mode');
  }

  if(!hasQueryParams(to) && hasQueryParams(from)){
    next({name: to.name, query: from.query});
  } else {
    next()
  }
})

export default router
